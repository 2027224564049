import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Autocomplete,
} from "@mui/material";
import { useParams } from "react-router";

const axios = require("axios");

export default function EditSetting(props) {
  const [setting, setSetting] = useState({});
  const [settingList, setSettingList] = useState([]);
  const params = useParams();

  var refresh = () => {
    window.location.reload(false);
  };

  const getSettingsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `settings${params?.id != 0 ? "/" + params.id : ""}`)
      .then((res) => {
        setSettingList(res.data);

        if (params?.id != 0) {
          setSetting({
            key: [
              "Certificate Number",
              "ULR number",
              "ULRNo",
              "SRF Number",
              "DC Number",
              "Remarks",
              "Other",
            ].includes(res.data[0].keyName)
              ? res.data[0].keyName
              : "Other",
            keyName: res.data[0].keyName,
            value: res.data[0].value,
          });
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const onSubmit = (event) => {
    let row = {
      keyName: setting.key !== "Other" ? setting.key : setting.keyName,
      value: setting.value,
      type: 2, // text
    };

    let url = BASE_URL;
    if (params?.id != 0) {
      axios
        .patch(url + `settings/${params?.id}`, row)
        .then((res) => {
          props.setLoader(false);
          toast("settings created successfully !");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          props.setLoader(false);
          toast.error("Something Went Wrong!");
        });
    } else {
      axios
        .post(url + `settings`, row)
        .then((res) => {
          props.setLoader(false);
          toast("settings created successfully !");
          setTimeout(refresh, 500);
        })
        .catch((error) => {
          props.setLoader(false);
          toast.error("Something Went Wrong!");
        });
    }
  };

  // data operations
  const updateSetting = (key, value) => {
    let newSetting = {
      ...setting,
    };
    newSetting[key] = value;
    setSetting(newSetting);
  };

  useEffect(() => {
    getSettingsList();
  }, []);
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create/Edit setting
      </Typography>

      <Grid container spacing={2} sx={{ pb: 2 }}>
        {params.id == 0 && (
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              value={params?.id != 0 || setting?.key ? setting?.key : "Other"}
              options={[
                { label: "Certificate Number" },
                { label: "ULR number" },
                { label: "SRF Number" },
                { label: "DC Number" },
                { label: "Remarks" },
                { label: "Other" },
              ]}
              renderInput={(params) => <TextField {...params} label="Key *" />}
              onInputChange={(e, newInputValue) => {
                updateSetting("key", newInputValue);
              }}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          {setting.key === "Other" && (
            <TextField
              id="outlined-basic"
              label="Key Name *"
              value={setting?.keyName}
              size="small"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateSetting("keyName", e.target.value);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            id="outlined-basic"
            label="Value *"
            value={setting?.value}
            multiline
            maxRows={10}
            rows={10}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSetting("value", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
