import axios from "axios";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { makeChunks } from "../helper/helpers";
import unitConvertor from "../../utils/components/unitConvertor";

const tablesData = {};

const tablesMap = {
  datasheet: "datasheets",
  instrument: "instruments",
  datasheeetStaticTable: "datasheetStaticTables",
  datasheetStaticReading: "datasheetStaticReadings",
  standard: "standards",
  standardRange: "standardRanges",
  supportiveInstrumentRange: "instruments",
  settings: "settings",
  // datasheetTemplate,
  // cmcs,
};

function findTokens(str) {
  const regex = /\$[\w.:'' ]+/g;
  const matches = str.match(regex);

  if (matches) {
    return matches;
  } else {
    return [];
  }
}

const fetchTables = async (queries) => {
  let chunks = makeChunks(Object.entries(queries));

  for (let chunk of chunks) {
    await Promise.all(
      chunk.map((e) =>
        axios.get(e[1]).then((res) => ({ name: e[0], value: res.data[0] }))
      )
    )
      .then((res) => {
        res.map((e) => (tablesData[e.name] = e.value));
      })
      .catch((err) => {
        console.error("err : ", err);
      });
  }
  return;
};

export const generateTableData = async (
  unceratinty,
  readings,
  tableId,
  datasheetId,
  instrumentId,
  uncertaintiesMap
) => {
  let conditions = unceratinty.map((e) => [e.id, JSON.parse(e.showcondition)]);
  let configs = unceratinty.map((e) => [e.id, JSON.parse(e.sourceconfig)]);
  let standards = readings.map((reading) => reading.standardRanges);
  let supportives = readings.map((reading) => reading.supportiveRanges);
  let readingIds = readings.map((reading) => reading.id);
  let tmp = {};
  let tables = {};
  let attributeTables = {};
  let idMaps = {
    settings: null,
    datasheet: datasheetId,
    instrument: instrumentId,
    datasheeetStaticTable: tableId,
    reading: readingIds.map((rdng, i) => ({
      datasheetStaticReading: rdng,
      masters: standards[i].map((std) => ({
        standard: std[0],
        standardRange: std[1],
      })),
      supportiveInstrument: supportives[i][0],
      supportiveInstrumentRange: supportives[i][1],
    })),
  };

  let tokens = {};
  let tp = [];

  conditions.map((c, i) => {
    tokens = [
      ...findTokens(Object.entries(c[1]).flat().join(",")),
      ...findTokens(Object.entries(configs[i][1]).flat().join(",")),
    ];
    tokens = tokens
      .map((token) => {
        tp = token.substring(1).split(".");
        tp[2] = tp[2]?.split(":");
        return tp;
      })
      .map((e, i) => {
        if (!tablesMap[e[0]]) {
          toast.warning("found wrong table in equation : " + tokens[i]);
          return null;
        }
        if (e[2]) {
          attributeTables[
            `${BASE_URL}${tablesMap[e[0]]}?_where=(${
              e[2][0]
            },eq,${e[2][1]?.replaceAll("'", "")})&_fields=${e[1]}`
          ] = [e[1]];
          return null;
        } else if (idMaps[e[0]]) {
          let id = idMaps[e[0]];
          let tId = `${BASE_URL}${tablesMap[e[0]]}_${id}`;
          if (tables[tId]) tables[tId][0][e[1]] = true;
          else tables[tId] = [{ [e[1]]: true }, `${e[0]}_${id}`, id];
          return null;
        }
        return e;
      })
      .filter((e) => e);
    if (tokens?.length > 0) tmp[c[0]] = tokens;
  });

  let tId = "";
  let id = "";
  readingIds.map((_, i) => {
    standards[i].map((std, j) => {
      Object.keys(tmp).map((t) => {
        if (uncertaintiesMap[std[0]]?.includes(t)) {
          tmp[t].map((e) => {
            id = idMaps.reading[i][e[0]]
              ? idMaps.reading[i][e[0]]
              : idMaps.reading[i].masters[j][e[0]];
            tId = `${BASE_URL}${tablesMap[e[0]]}_${id}`;
            if (tables[tId]) {
              tables[tId][0][e[1]] = true;
            } else {
              tables[tId] = [{ [e[1]]: true }, `${e[0]}_${id}`, id];
            }
            if (e[0] == "standardRange" && !tables[tId][0]["rangeName"]) {
              tables[tId][0]["rangeName"] = true;
            }
          });
        }
      });
    });
  });

  let queries = {};
  let tc = "";
  Object.entries(attributeTables).map((e) => (queries[e[1]] = e[0]));
  Object.entries(tables).map((e) => {
    queries[e[1][1]] = `${e[0].split("_")[0]}?_fields=${Object.keys(
      e[1][0]
    ).join(",")}&_where=(id,eq,${e[1][2]})`;
  });
  await fetchTables(queries);
};

export const convertUnit = (val, from, to, V1, V2) => {
  val = val || 0;
  return from && to
    ? (() => {
        try {
          return unitConvertor(val, from, to, V1, V2);
        } catch (err) {
          return val;
        }
      })()
    : val;
};

const resolveToken = (token, selectors) => {
  let tp = token.substring(1).split(".");
  tp[2] = tp[2]?.split(":");
  if (!tp[2]) {
    token = `${tp[0]}_${selectors[tp[0]]}`;
  }
  return tablesData[token]?.[tp[1]];
};

export const check_percentage = (unit, rl, rh, cp) => {
  if (unit === "%FSD") return [cp];
  else if (unit === "%RDG") return [rl, rh];
  return [];
};

export const resolveUncertaintyValue = (
  unceratinty,
  selectors,
  unitMap,
  cp
) => {
  let config = JSON.parse(unceratinty.sourceconfig);
  let conditions = Object.keys(config);
  let trueCondition = "default";
  for (const element of conditions) {
    let condition = element;
    if (condition == "default") continue;
    let updatedCondition = condition;
    let tokens = findTokens(condition).filter((token) => token != "default");
    for (const element of tokens) {
      let token = element;
      let tokenResult = resolveToken(token, selectors);
      if (/\D/.test(tokenResult) || tokenResult == "")
        tokenResult = `'${tokenResult}'`;
      updatedCondition = updatedCondition.replace(token, tokenResult);
    }

    let res = Function("return " + updatedCondition)();
    if (res === true) {
      trueCondition = condition;
      break;
    }
  }

  let sourceFormula = config[trueCondition];
  let updatedFormula = config[trueCondition];
  let ranges,
    V = [];
  if (updatedFormula === "") return 0;
  // find values from table references
  let tokens = findTokens(sourceFormula);
  for (let i = 0; i < tokens?.length; i++) {
    let raw_value = resolveToken(tokens[i], selectors);
    let value = 0;
    let unit = "";
    if (raw_value && raw_value?.[0]) {
      value = raw_value?.split("#")[0];
      unit = raw_value?.split("#")[1] || "";
      if (unit && unitMap) {
        if (
          sourceFormula.includes("standardRange") &&
          (unit === "%FSD" ||
            unit === "%RDG" ||
            unitMap === "%FSD" ||
            unitMap === "%RDG")
        ) {
          ranges = resolveToken("$standardRange.rangeName", selectors)
            ?.split("|")
            .map((e) => e?.split("#"));
          V =
            check_percentage(unit, ranges[0][0][0], ranges[1][0][0], cp[0]) ||
            check_percentage(unitMap, ranges[0][0][0], ranges[1][0][0], cp[0]);
        }
        value = convertUnit(value, unit, unitMap, V[0], V[1]);
      }
    }

    updatedFormula = updatedFormula?.replace(tokens[i], value);
  }
  let res = Function("return " + updatedFormula)();
  updatedFormula = res;
  return updatedFormula;
};
