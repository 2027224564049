import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  createClientThunk,
  createEnquiryThunk,
  getEnquiryData,
  getEnquiryThunk,
  normalize,
  updateEnquiryThunk,
} from "./enquirySlice";
const axios = require("axios");

const sourceArr = [
  {
    id: 0,
    label: "Visit",
  },
  {
    id: 1,
    label: "Tele",
  },
  {
    id: 2,
    label: "Justdial",
  },
  {
    id: 3,
    label: "Customer Call",
  },
];

export default function CreateEnquiry(props) {
  const data = useSelector(getEnquiryData);
  const [state, setState] = useState({
    form: {},
    clientArray: [],
    clientId: -1,
    checkedClient: false,
  });
  const userId = localStorage.getItem("id");
  const params = useParams();
  const dispatch = useDispatch();

  const refresh = () => {
    window.location.reload(false);
  };

  if (data.created) {
    toast.success("Enquiry Created Successfully!");
    dispatch(normalize());
    setTimeout(() => refresh(), 500);
  }

  if (data.updated) {
    toast.success("Enquiry Updated Successfully!");
    dispatch(normalize());
  }

  const onSubmit = (event) => {
    let row = {
      ...state.form,
      source:
        state.form?.source?.id !== undefined
          ? parseInt(state.form?.source?.id)
          : data.enquiry.en_source
          ? parseInt(data.enquiry.en_source)
          : data.enquiry.en_source,
      createdby: userId,
    };
    row.date = moment(row.date).format("YYYY-MM-DD");
    row.followUpDate = moment(row.followUpDate).format("YYYY-MM-DD");
    delete row.client;
    delete row.address;
    if (params.id) dispatch(updateEnquiryThunk({ data: row, id: params.id }));
    else dispatch(createEnquiryThunk({ data: row }));
  };

  const saveClient = (event) => {
    let row = {
      companyName: state.companyName,
      address: state.clientAddress,
      contact: JSON.stringify([
        {
          contactPersonName: state.contact,
          emailId: state.email,
        },
      ]),
    };
    dispatch(createClientThunk({ data: row }));
  };

  const updateForm = (key, value) => {
    let newForm = {
      ...state.form,
    };
    newForm[key] = value;
    setState({
      ...state,
      form: newForm,
    });
  };

  useEffect(() => {
    if (params.id) dispatch(getEnquiryThunk({ id: params.id }));
    else dispatch(getEnquiryThunk());
  }, []);

  useEffect(() => {
    if (data.loading === 2) window.location.reload(false);
  });

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        {`${params.id ? "Edit" : "Create New"} Enquiry`}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={data.clients}
            value={
              state?.form?.client ||
              data?.clients?.filter((c) => c.id == data.enquiry.client_id)[0] ||
              ""
            }
            getOptionLabel={(option) => option.companyName || ""}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onChange={(_, val) => {
              console.log("val", val);
              setState({
                ...state,
                form: {
                  ...state.form,
                  client: val ? val : "",
                  clientId: val ? val.id : "",
                  address: val ? val.address : "",
                  contactPersonName: val?.contact?.[0]?.contactPersonName || "",

                  emailId: val ? val.contact?.[0]?.emailId : "",
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address *"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={state.form.address || data.enquiry.client_address || ""}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Contact Person Name *"
            size="small"
            value={
              state.form.contactPersonName ||
              data.enquiry.en_contactPersonName ||
              ""
            }
            // defaultValue={`${data.enquiry.en_contactPersonName || ""}`}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateForm("contactPersonName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="email Id *"
            size="small"
            value={state.form.emailId || data.enquiry.en_emailId || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateForm("emailId", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={
              state.form.source ||
              sourceArr.filter(
                (s) => s.id === parseInt(data.enquiry.en_source)
              )[0] ||
              ""
            }
            options={sourceArr}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => <TextField {...params} label="Source *" />}
            onChange={(_, val) => updateForm("source", val)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={
              state.form.leadStatus ||
              leadStatusArr.filter(
                (l) => l.id === parseInt(data.enquiry.en_leadStatus)
              )[0] ||
              ""
            }
            options={leadStatusArr}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => (
              <TextField {...params} label="Lead Status *" />
            )}
            onChange={(_, val) => updateForm("leadStatus", val)}
          />
        </Grid> */}
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                state.form.date
                  ? new Date(state.form.date)
                  : data.enquiry.en_date
                  ? new Date(data.enquiry.en_date)
                  : new Date()
              }
              onChange={(newValue) => {
                updateForm("date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Followup Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                state.form.followUpDate
                  ? new Date(state.form.followUpDate)
                  : data.enquiry.en_followUpDate
                  ? new Date(data.enquiry.en_followUpDate)
                  : new Date()
              }
              onChange={(newValue) => {
                updateForm("followUpDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            style={{
              width: "100%",
            }}
            value={
              state.form.requirements || data.enquiry.en_requirements || ""
            }
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Requirements"
            onChange={(e) => {
              updateForm("requirements", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            style={{
              width: "100%",
            }}
            value={
              state.form.leadFollowup || data.enquiry.en_leadFollowup || ""
            }
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Lead Followe-up"
            onChange={(e) => updateForm("leadFollowup", e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setState({
                    ...state,
                    checkedClient: e.target.checked,
                  });
                }}
              />
            }
            label="Create client"
          />
        </Grid>
      </Grid>
      {state.checkedClient == true ? (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Company Name *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setState({
                  ...state,
                  companyName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Address"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setState({
                  ...state,
                  clientAddress: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Contact Prtson Name *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setState({
                  ...state,
                  contact: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Email *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setState({
                  ...state,
                  email: e.target.value,
                });
              }}
            />
          </Grid>
          <Toolbar style={{ padding: "15px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={() => {
                saveClient();
              }}
            >
              Save
            </Button>
          </Toolbar>
        </Grid>
      ) : (
        ""
      )}
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // props.setLoader(true);
            onSubmit();
          }}
        >
          {`${params.id ? "Update" : "Create"}`}
        </Button>
      </Toolbar>
    </Paper>
  );
}
