import axios from "axios";
import { BASE_URL } from "../../global";


export async function countOfCertificatesAnalytics(ids) {
    try {
        for (const id of ids) {
            const url = `${BASE_URL}dynamic`;

            const updateQueryPayload = {
                query: `UPDATE certificateAnalytics SET certificateDownloadCounter = certificateDownloadCounter + 1 WHERE certificateId = ${id}`
            };
          const Res=  await axios.post(url, updateQueryPayload);

            if (Res?.affectedRows === 0) {
                const insertQueryPayload = {
                    query: `INSERT INTO certificateAnalytics (certificateId) VALUES (${id})`
                };
                await axios.post(url, insertQueryPayload);
            } 


              
            
        }
    } catch (error) {
        console.error("Error creating/updating certificate Analytics:", error);
    }
}


export async function countOfStickerAnalytics(ids) {
    try {
        for (const id of ids) {
            const url = `${BASE_URL}dynamic`;
            const countQueryPayload = {
                query: `SELECT COUNT(*) FROM certificateAnalytics WHERE certificateId = ${id};`
            };

            const response = await axios.post(url, countQueryPayload);
            const count = response.data?.[0]?.['COUNT(*)'];
console.log("ID and count",id,count)
            if (count === 0) {
                const insertQueryPayload = {
                    query: `INSERT INTO certificateAnalytics (certificateId) VALUES (${id})`
                };
                await axios.post(url, insertQueryPayload);
            } 


                const updateQueryPayload = {
                    query: `UPDATE certificateAnalytics SET stickerDownloadCounter = stickerDownloadCounter + 1 WHERE certificateId = ${id}`
                };
                await axios.post(url, updateQueryPayload);
            
        }
    } catch (error) {
        console.error("Error creating/updating certificate Analytics:", error);
    }
}



