import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Autocomplete, Button, Checkbox, Grid, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIcon from "@mui/icons-material/Edit";

const DisplayTable = ({
  value,
  standards,
  uncertaintyMasterData,
  state,
  setState,
}) => {
  const [tableData, setTableData] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [newRowIndex, setNewRowIndex] = useState(-1);
  const [filteredStandards, setFilteredStandards] = useState([]);

  const updateMaster = (newMasterObject) => {
    newMasterObject = newMasterObject
      .map((e) =>
        Object.values(e)
          .map((e2, i) => (i == 1 ? e2.join(",") : e2))
          .join(":")
      )
      .join("|");
    let st = {};
    st.standardMasterIdArray = newMasterObject == "" ? null : newMasterObject;
    setState({ ...st });
  };

  const addRow = () => {
    setNewRowIndex(tableData.length);
    setEditingRowIndex(tableData.length);
    const newTableData = [
      ...tableData,
      { standardMasterId: null, uncertaintyFactors: [] },
    ];
    setTableData(newTableData);
    updateMaster(newTableData);
  };

  const deleteRow = (rowIndex) => {
    let newReadingRows = [...tableData];
    newReadingRows.splice(rowIndex, 1);
    setTableData(newReadingRows);
    updateMaster(newReadingRows);
  };

  const handleChange = (index, field, newValue) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = newValue;
    setTableData(updatedTableData);
    updateMaster(updatedTableData);
  };

  const filterStandards = (tableDataIds) => {
    if (!tableDataIds) tableDataIds = tableData.map((e) => e.standardMasterId);
    const rFilteredStandards = state.filteredStandards.filter(
      (standard) => !tableDataIds.includes(standard.id.toString())
    );
    setFilteredStandards(rFilteredStandards);
  };

  useEffect(() => {
    if (value) {
      const tableDataIds = [];
      const parsedTableData = value.split("|").map((row) => {
        const [standardMasterId, uncertaintyFactors] = row.split(":");
        tableDataIds.push(standardMasterId);
        return {
          standardMasterId: standardMasterId || null,
          uncertaintyFactors: uncertaintyFactors?.includes(",")
            ? uncertaintyFactors?.split(",").map((e) => e)
            : [],
        };
      });
      // if (newRowIndex !== -1) {
      //   parsedTableData.push(tableData[newRowIndex]);
      //   if (
      //     tableData[newRowIndex].standardMasterId !== null &&
      //     tableData[newRowIndex].standardMasterId !== undefined &&
      //     tableData[newRowIndex].standardMasterId !== ""
      //   )
      //     tableDataIds.push(tableData[newRowIndex].standardMasterId);
      //   setNewRowIndex(parsedTableData.length - 1);
      //   setEditingRowIndex(parsedTableData.length - 1);
      // }
      setTableData(parsedTableData);
      filterStandards(tableDataIds);
    }
  }, [value]);

  console.log("state.filteredStandards", state.filteredStandards);
  console.log("filteredStandards", filteredStandards);

  const disabledCondition = () => {
    return (
      newRowIndex !== -1 &&
      newRowIndex === tableData.length - 1 &&
      (tableData?.[newRowIndex]?.standardMasterId === "" ||
        tableData?.[newRowIndex]?.standardMasterId === undefined ||
        tableData?.[newRowIndex]?.standardMasterId === null ||
        tableData?.[newRowIndex]?.uncertaintyFactors === "" ||
        tableData?.[newRowIndex]?.uncertaintyFactors === undefined ||
        tableData?.[newRowIndex]?.uncertaintyFactors === null ||
        tableData?.[newRowIndex]?.uncertaintyFactors?.length === 0)
    );
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={2}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={addRow}
            startIcon={<AddIcon />}
            disabled={disabledCondition()}
          >
            ADD Masters
          </Button>
        </Grid>
      </Grid>

      <br />
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th>Standard Master ID</th>
            <th>Uncertainty Factors</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td width="400px">
                {editingRowIndex === rowIndex ? (
                  <Autocomplete
                    disabled={rowIndex !== newRowIndex}
                    fullWidth
                    id={`standard-master-autocomplete-${rowIndex}`}
                    size="small"
                    options={filteredStandards || []}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      `${option.stId}: ${option.standardName}`
                    }
                    value={
                      state.filteredStandards.find(
                        (option) => option.id == row?.standardMasterId
                      ) || null
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.stId} <b>:</b> {option.standardName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Standard Master"
                      />
                    )}
                    onChange={(event, value) => {
                      handleChange(
                        rowIndex,
                        "standardMasterId",
                        value ? value.id : null
                      );
                      // setState({
                      //   ...state,
                      //   selectedStandardMasterId: value ? value.id : null,
                      // });
                    }}
                  />
                ) : (
                  <div>
                    {state.filteredStandards.find(
                      (option) => option.id == row?.standardMasterId
                    )
                      ? `${
                          state.filteredStandards.find(
                            (option) => option.id == row?.standardMasterId
                          ).stId
                        } : ${
                          state.filteredStandards.find(
                            (option) => option.id == row?.standardMasterId
                          ).standardName
                        }`
                      : "Standard not found"}
                  </div>
                )}
              </td>
              <td>
                {editingRowIndex === rowIndex ? (
                  <Autocomplete
                    multiple
                    fullWidth
                    id={`uncertainty-factors-autocomplete-${rowIndex}`}
                    size="small"
                    options={state.uncertaintyMasterData || []}
                    value={row.uncertaintyFactors.map((id) =>
                      state.uncertaintyMasterData.find((item) => item.id == id)
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name || ""}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.id} <b>:</b> {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Uncertainty Factors"
                      />
                    )}
                    onChange={(event, value) => {
                      handleChange(
                        rowIndex,
                        "uncertaintyFactors",
                        value ? value.map((item) => item.id) : []
                      );
                      // setState({
                      //   ...state,
                      //   selectedUncertaintyFactors: value
                      //     ? value.map((item) => item.id)
                      //     : [],
                      // });
                    }}
                  />
                ) : (
                  <div>
                    {row.uncertaintyFactors.map((id) => {
                      const uncertaintyFactor =
                        state.uncertaintyMasterData.find(
                          (item) => item.id == id
                        );
                      return uncertaintyFactor
                        ? `${uncertaintyFactor.name}, `
                        : null;
                    })}
                  </div>
                )}
              </td>
              <td style={{ minWidth: "80px" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <EditIcon
                    style={{ color: "#1976d2", cursor: "pointer" }}
                    onClick={() => setEditingRowIndex(rowIndex)}
                    sx={
                      newRowIndex !== rowIndex
                        ? {
                            pointerEvents: disabledCondition() ? "none" : "",
                            cursor: disabledCondition() ? "not-allowed" : "",
                            opacity: disabledCondition() ? 0.5 : 1,
                          }
                        : {}
                    }
                  />
                  <DeleteIcon
                    style={{ color: "#dc3545", cursor: "pointer" }}
                    onClick={() => deleteRow(rowIndex)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DisplayTable;
