import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No", minWidth: 10 },
  { id: "instrumentName", label: "Instrument Name", minWidth: 30 },
  { id: "discipline", label: "Discipline", minWidth: 30 },
  {
    id: "calibrationProcedureNo",
    label: "Calibration Procedure No",
    minWidth: 30,
  },
  { id: "referenceStandards", label: "Referenece Standards", minWidth: 30 },
];

function createData(
  id,
  instrumentName,
  discipline,
  calibrationProcedureNo,
  referenceStandards
) {
  return {
    id,
    instrumentName,
    discipline,
    calibrationProcedureNo,
    referenceStandards,
  };
}

export default function InstrumentsList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [instruments, setInstrumentList] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    console.log(id);

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `instruments/${id}`, jsonData)
      .then((res) => {
        toast("Instrument was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  function getClientList() {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Instrument Name": "inst.instrumentName",
        "Calibration Procedure No.": "inst.calibrationProcedureNo",
        Discipline: "ds.name",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` lastModified between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (whereConditions == "") {
      whereConditions += " where inst.status = 1  ";
    } else {
      whereConditions += " and inst.status = 1  ";
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `select ds.name,inst.id,inst.instrumentName,inst.calibrationProcedureNo,inst.referenceStandards from instruments as inst left join discipline as ds on inst.disciplineId = ds.id ${whereConditions} order by inst.id DESC ${pagination_settings}`,
    };
    console.log(data.query);
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setInstrumentList([...res.data]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    axios
      .get(BASE_URL + `instruments/count`)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  var rowDaata = [];
  for (let i = 0; i < instruments.length; i++) {
    rowDaata.push(
      createData(
        instruments[i].id,
        instruments[i].instrumentName,
        instruments[i].name,
        instruments[i].calibrationProcedureNo,
        instruments[i].referenceStandards
      )
    );
  }

  const copyInstrument = async (id) => {
    let instrument = await axios
      .get(BASE_URL + `instruments/${id}`)
      .then((res) => res.data[0] || []);
    delete instrument.id;
    delete instrument.lastModified;
    let cmcs = await axios
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${id})`)
      .then((res) => res.data || []);
    axios.post(BASE_URL + "instruments", instrument).then((res) => {
      cmcs.map((cmc) => {
        delete cmc.id;
        delete cmc.lastModified;
        cmc.instrumentId = res.data.insertId;
      });
      axios
        .post(BASE_URL + "cmc/bulk", cmcs)
        .then((res2) => setTimeout(refresh, 500));
    });
    console.log("data : ", instrument, cmcs);
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/instrument/createInstrument"
        >
          <b>ADD</b>
        </Button>
      </Toolbar>
      <br />
      <div
        className="mb-2"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            marginLeft: "20px",
          }}
        >
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="combo-box-demo"
            options={[
              { key: "instrumentName", label: "Instrument Name" },
              {
                key: "calibrationProcedureNo",
                label: "Calibration Procedure No.",
              },
              { key: "discipline", label: "Discipline" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              search();
            }}
          >
            <SearchIcon />
          </Button>
        </div>
      </div>
      <br />

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
        width="100%"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDaata.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  let value = row[column.id];
                  if (column.id == "id") {
                    value = page * rowsPerPage + (index + 1);
                  }
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <ButtonGroup size="small" aria-label="small button group">
                    <Tooltip title="Edit Instrument" placement="top-start">
                      <Button
                        component={Link}
                        to={`/master/instrument/editInstrument/${row.id}`}
                      >
                        <PreviewIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Copy Instrument" placement="top-start">
                      <Button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Do want to copy and create new Instrument using selected instrument?"
                            )
                          ) {
                            copyInstrument(row.id);
                          }
                        }}
                      >
                        <ContentCopyIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete Instrument" placement="top-start">
                      <Button
                        onClick={(e) => {
                          if (window.confirm("Really want to delete Instrument?")) {
                            submitSiteDelete(row.id);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#dc3545" }} />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
  );
}
