import React from "react";
import { toPng } from "html-to-image";
import html2pdf from "html2pdf.js";
import moment from "moment";
import UnitechFooter from "../image/certificate_footer.png";
import UnitechHeader from "../image/certificate_header.png";
import Logo from "../image/logo.png";
import {
  Autocomplete,
  Button,
  TableRow,
  Table,
  Grid,
  TextField,
} from "@mui/material";
import { makeChunks } from "../helper/helpers";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

export default function ViewDatasheet(props) {
  // console.log("props", props);
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);

  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CALIBRATED BY <br> Electronically signed by </b>
                <br>
                <span style="font-size:19px;">${calibratedby}<br>${moment(
                    calibrationDate
                  ).format("DD-MM-YYYY")}</span>
            </td>`
                : `<td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;vertical-align: top;">CALIBRATED BY</b>
            </td>`
            }
            </td>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CHCECKED AND APPROVED BY <br> Electronically signed by </b> <br>
                <span style="font-size:19px;">${approvedby}<br>${moment(
                    calibrationDate
                  ).format("DD-MM-YYYY")}</span>`
                : `
            <td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:17px;vertical-align: top;">CHCECKED AND APPROVED BY </b>`
            }
            </td>
        </tr>
        <tr>
        <td colspan="2" style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generatePDF = async () => {
    const element = props.printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [10, 1, 165, 1],
        filename: `certificate_${props.datasheetId}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      let image = await generateDynamicFooter(
        props.calibratedby,
        props.approvedby,
        props.calibrationDate,
        i,
        totalPages
      );
      pdf.addImage(image, 0, 675, 595, 100);

      if (letterHead) {
        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 772, 595, 70);
      }
    }

    pdf.save();
  };

  function headerrowDetailsTable() {
    return (
      <table className="standardUnitTable " align="left">
        <tr>
          <td rowspan="4" style={{ borderRight: "none" }}>
            <img src={Logo} height="60px" />
          </td>
          <td rowspan="4" style={{ borderRight: "none" }}>
            <b style={{ fontSize: "14px" }}>
              LABCITY CALIBRATION SERVICES <br />
              Observation Sheet - {props.instrumentName}
            </b>
          </td>
        </tr>
        <tr>
          <td style={{ borderRight: "none", borderBottom: "none" }}>
            <b>Document Number</b>
          </td>
          <td style={{ borderBottom: "none" }}>{props.documentNumber}</td>
        </tr>
        <tr>
          <td style={{ borderRight: "none", borderBottom: "none" }}>
            <b>Issue No. / Date</b>
          </td>
          <td style={{ borderBottom: "none" }}>
            {props.issueNo} / {moment(props.issueDate).format("DD/MM/YYYY")}
          </td>
        </tr>
        <tr>
          <td style={{ borderRight: "none" }}>
            <b>Amendment No. / Date</b>
          </td>
          <td>
            {props.amendmentNo} /{" "}
            {moment(props.amendmentDate).format("DD/MM/YYYY")}
          </td>
        </tr>
      </table>
    );
  }

  function customerDetailsTable() {
    return (
      <table className="standardUnitTable single-border-table" align="center">
        <tr>
          <td style={{ width: "16.66%" }}>
            <b>Purpose</b>
          </td>
          <td style={{ width: "16.66%" }}>Calibration</td>
          <td style={{ width: "16.66%" }}>
            <b>SRF Number</b>
          </td>
          <td style={{ width: "16.66%" }}>{props.SRFNumber}</td>
          <td style={{ width: "16.66%" }}>
            <b>SRF Date</b>
          </td>
          <td style={{ width: "16.66%" }}>
            {moment(props.entryDate).format("DD/MM/YYYY")}
          </td>
        </tr>
        <tr>
          <td style={{ width: "16.66%" }}>
            <b>Certificate Number</b>
          </td>
          <td style={{ width: "16.66%" }}>{props.certificateNumber}</td>
          <td style={{ width: "16.66%" }}>
            <b>Date Calibration</b>
          </td>
          <td style={{ width: "16.66%" }}>
            {moment(props.calibrationDate).format("DD/MM/YYYY")}
          </td>
          <td style={{ width: "16.66%" }}>
            <b>Frequency of Calibration</b>
          </td>
          <td style={{ width: "16.66%" }}>
            {moment(props.nextDueDate).format("DD/MM/YYYY")}
          </td>
        </tr>
      </table>
    );
  }

  function unitCalibrationDetails() {
    return (
      <Table
        className={props.classes.table}
        style={{ fontSize: "11px", marginBottom: "3px" }}
      >
        <TableRow className={props.classes.row}>
          <TableCell className={props.classes.infoCell} align="center">
            <TableCell
              style={{
                border: "none",
                width: "150px",
                padding: "0px",
              }}
            >
              <b>Name of Instrument </b>
            </TableCell>
            <TableCell style={{ border: "none" }}>:</TableCell>
            <TableCell style={{ border: "none" }}>
              {props.requestedname.length > 0
                ? props.requestedname
                : props.instrumentName}
            </TableCell>
          </TableCell>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>SRF No.</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {props.SRFNumber}
              </TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Make</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{props.make}</TableCell>
            </TableRow>
          </TableCell>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Identification No.</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{props.DUCID}</TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Model</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{props.models}</TableCell>
            </TableRow>
          </TableCell>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Serial no. </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{props.serialNo}</TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Range</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {props.ranges
                  ?.replaceAll("||", ", ")
                  ?.replaceAll("|", " to ")
                  ?.replaceAll("#", " ")}
              </TableCell>
            </TableRow>
          </TableCell>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Accuracy</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {props.accuracy
                  ? `± ${props.accuracy
                      ?.replaceAll("||", ", ")
                      ?.replaceAll("|", " to ")
                      ?.replaceAll("#", " ")}`
                  : ""}
              </TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Resolution/Least Count</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {props.lc
                  ?.replaceAll("||", ", ")
                  ?.replaceAll("|", " to ")
                  ?.replaceAll("#", " ")}
              </TableCell>
            </TableRow>
          </TableCell>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Location</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {props.locationOfInstrument ? props.locationOfInstrument : ""}
              </TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Calibration Location </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{props.location}</TableCell>
            </TableRow>
          </TableCell>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Condition of Instrument </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>{props.ConOfDuc}</TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Calibration Procedure</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {props.calibrationProcedureNo}
              </TableCell>
            </TableRow>
          </TableCell>
          <TableCell className={props.classes.infoCell}>
            <TableRow style={{ border: "none", padding: "0px" }}>
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Standard Reference</b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {props.referenceStandards}
              </TableCell>
            </TableRow>
          </TableCell>
        </TableRow>
        {makeChunks(
          props.extraColumns.filter((e) => e[1]),
          2
        ).map((e) => (
          <TableRow>
            <TableCell className={props.classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>{e[0]?.[0] || ""}</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {e[0]?.[1] || ""}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={props.classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>{e[1]?.[0] || ""}</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {e[1]?.[1] || ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    );
  }

  function masterDataTable() {
    //Please don't put thead or tbody
    let selected_masters = props.getSelectedMasters();
    return (
      <div align="center">
        <b>DETAILS OF STANDARD USED FOR CALIBRATION</b>
        <br></br>
        <table className="standardUnitTable single-border-table" align="center">
          <tr>
            <td>
              <b>Instrument Name</b>
            </td>
            <td>
              <b>Id/Sr. No. </b>
            </td>
            <td>
              <b>Certificate No.</b>
            </td>
            <td>
              <b>Calibrated By</b>
            </td>
            <td>
              <b>Calibration Due Date</b>
            </td>
          </tr>
          {selected_masters.map((row, index) => (
            <tr key={row.name}>
              <td>{row.standardName}</td>
              <td>{row.stId}</td>
              <td>{row.certificateNumber}</td>
              <td>{row.traceability}</td>
              <td>{moment(row.validUpto).format("DD-MM-YYYY")}</td>
            </tr>
          ))}
        </table>
      </div>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        align="left"
        style={{ display: "flex", margin: "15px 3px" }}
      >
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Electronically Signed" },
              { id: 2, label: "Without  Electronically Signed" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Electronically Signed" />
            )}
            onInputChange={(event, newInputValue) => {
              console.log("newValue : ", newInputValue);
              if (
                newInputValue === "With Electronically Signed" ||
                newInputValue === ""
              ) {
                setElectronicSign(true);
              } else if (newInputValue === "Without  Electronically Signed") {
                setElectronicSign(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <div id="printOnly2" ref={props.printComponentRef}>
        <div style={{ width: "100%" }} id="header-section"></div>

        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ border: "none" }}>
                <div
                  class="content"
                  style={{
                    fontSize: "11px",
                    margin: "20px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Table>
                    {headerrowDetailsTable()}
                    <h5 style={{ textAlign: "center", marginBottom: "5px" }}>
                      <b>
                        <u>OBSERVATION SHEET</u>
                      </b>
                    </h5>
                    {customerDetailsTable()}
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <b>DETAILS OF UNIT UNDER CALIBRATION (UUC)</b>
                      </TableCell>
                    </TableRow>
                    {unitCalibrationDetails()}
                    <Table
                      className={props.classes.table}
                      style={{ marginBottom: "3px" }}
                    >
                      <TableRow>
                        <TableCell>
                          <b>Environmental Conditions: </b>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>
                              Temperature :
                            </b>
                            {props.startTemp} C°
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>Humidity:</b>
                            {props.startHumidity} %RH
                          </span>
                        </TableCell>
                      </TableRow>
                    </Table>
                    {masterDataTable()}
                  </Table>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ marginBottom: "5px", marginTop: "10px" }}>
                        <b>DISCIPLINE : </b>
                        {props.disciplineName}
                      </div>
                      <div className="row p-0 m-0">
                        {props.renderStaticTables()}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            border: "1px solid black",
                            padding: "5px",
                            flex: "1",
                          }}
                          className="break-remark"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div style={{ float: "left" }}>
                                <b
                                  style={{ float: "left", fontSize: "11px" }}
                                  className="standardUnitTable"
                                >
                                  Remarks :
                                </b>
                              </div>
                            </div>
                          </div>
                          <div className="text-start" style={{}}>
                            <pre
                              style={{
                                fontFamily: " Calibri, sans-serif",
                                fontSize: "10px",
                                marginBottom: "0px",
                                lineHeight: 1,
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {props.configuration?.remark ||
                                props.defaultReamrk}
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    --------- END OF OBSERVATION SHEET ---------
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
