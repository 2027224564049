import React, { Component, useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useParams } from "react-router";
import DisplayTable from "./displayTable";
const axios = require("axios");
const materialtypeOp = ["steel", "carbite"];

const mode = ["Source", "Measure"];

const parameters = {
  Source: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @",
    "DC Power",
    "Conductivity meter",
    "pH Meter",
  ],
  Measure: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)",
  ],
};

const paratype = {
  Source: {
    Oscilloscope: [
      "Amplitude",
      "AC Amplitude @ 50 Hz",
      "DC Amplitude",
      "Time Base",
      "Frequency",
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
    ],
  },
  Measure: {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)",
    ],
  },
};

let gaugeArr = [
  { id: 1, label: "Plain plug Gauge" },
  { id: 2, label: "Width gauge" },
  { id: 3, label: "Paddle gauge" },
  { id: 4, label: "Flush pin" },
  { id: 5, label: "Snap gauge" },
];

//column object format
/*
 * column ={
      table,
 *    field 
 * }
 */

const fixedColumns = [
  [
    {
      field: "id",
      headerName: "Sr. No.",
      editable: false,
    },
    {
      field: "mode",
      headerName: "Mode",
      editable: true,
      toggle: 1,
    },
    {
      field: "parameter",
      headerName: "Parameter",
      editable: true,
      toggle: 1,
    },
    {
      field: "paratype",
      headerName: "Parameter Type",
      editable: true,
      toggle: 1,
    },
    {
      field: "fromRange",
      headerName: "From range",
      editable: true,
    },
    {
      field: "toRange",
      headerName: "To range",
      editable: true,
    },
    {
      field: "CMCPLUS",
      headerName: "Lower CMC",
      editable: true,
    },
    {
      field: "CMSMINUS",
      headerName: "Higher CMC",
      editable: true,
    },
    {
      field: "Action",
      headerName: "Action",
    },
  ],
];

const cmcColumnMapping = {};

const CreateInstrument = (props) => {
  const [state, setState2] = useState({
    standards: [],
    filteredStandards: [],
    disciplineValue: {},
    uncertaintyMasterData: [],
    units: [],
    finalUnitArray: [],
    disciplines: [],
    disciplineArr: [],
    disciplineId: null,
    instrumentNameId: null,
    calibrationProcedureNo: null,
    referenceStandards: null,
    standardMaster: null,
    supportiveIds: null,
    uncertaintyMaster: [],
    standardMasterIdArray: null,
    updateEnableDisableO: null,
    readingRows: [["", "", "", "", "", "", "", ""]],
    readingRangeValue: [["", "", "", "", "", "", "", ""]],
    materialType: "steel",
    et: true,
    readingColumns: fixedColumns,
    supportive: null,
    formatNumber: "",
    revisionNumber: "",
    revisionDate: "",
  });
  const params = useParams();
  console.log("state", state);
  const setState = (val) => {
    console.log("setState : ", state, val);
    setState2({
      ...state,
      ...val,
    });
  };

  const refresh = () => {
    window.location.reload(false);
  };

  const filterStandards = (inpt, dsid) => {
    if (dsid) return inpt.filter((e) => e.disciplineId === dsid);
    return inpt;
  };

  const retErr = (errTxt, err) => {
    console.log(errTxt, err);
    return "err";
  };

  const getData = () => {
    Promise.all(
      [
        fetchDeciplineData(),
        fetchStandardsData(),
        fetchSupportiveData(),
        fetchUncertinityData(),
        getUnitMasterArray(),
      ].concat(params.id ? [fetchInstrumentData(), getCmcReadings()] : [])
    )
      .then((res) => {
        let disciplineArr = [];
        for (var i = 0; i < res[0].length; i++) {
          disciplineArr.push({
            label: res[0][i].name,
            id: res[0][i].id,
          });
        }
        let st = {
          disciplines: res[0],
          disciplineArr: disciplineArr,
          standards: res[1],
          filteredStandards: filterStandards(res[1]),
          supportive: res[2].map((item) => {
            return {
              id: item.pl_id,
              standardRangesId: item.pr_id,
              title: item.pl_standardName,
              range: item.pr_rangeName,
            };
          }),
          uncertaintyMasterData: res[3],
          units: res[4],
        };
        if (params.id) {
          console.log("instrument data : ", res);
          let instr = { ...res[5] };
          let sim = [[], []];
          (instr.inst_supportiveInstrumentMasterData || "")
            .split(",")
            .map((e) => {
              sim[0].push(parseInt(e.split(":")[0]));
              sim[1].push(parseInt(e.split(":")[1]));
            });
          let cmcReadings = res[6];
          let rows = [];
          let rowsSpace = [];
          try {
            for (let i = 0; i < cmcReadings.length; i++) {
              rowsSpace.push([
                cmcReadings[i].id,
                "",
                "",
                "",
                (cmcReadings[i].fromRange || "#").split("#")[1],
                (cmcReadings[i].toRange || "#").split("#")[1],
                (cmcReadings[i].lowerCmc || "#").split("#")[1],
                (cmcReadings[i].higherCmc || "#").split("#")[1],
              ]);
              rows.push([
                cmcReadings[i].id,
                (cmcReadings[i].mode || "#").split("#")[0],
                (cmcReadings[i].parameter || "#").split("#")[0],
                (cmcReadings[i].paratype || "#").split("#")[0],
                (cmcReadings[i].fromRange || "#").split("#")[0],
                (cmcReadings[i].toRange || "#").split("#")[0],
                (cmcReadings[i].lowerCmc || "#").split("#")[0],
                (cmcReadings[i].higherCmc || "#").split("#")[0],
              ]);
            }
          } catch (error) {
            console.log(error);
          }
          let disciplineKeys = false;
          st.disciplines.forEach((el) => {
            if (el.id == instr.ds_id) {
              if (el.disciplineKey == "0") {
                disciplineKeys = true;
              }
            }
          });
          if (disciplineKeys) {
            st.et = false;
          } else {
            st.et = true;
          }
          const unitArr = [""];
          for (let i = 0; i < st.units.length; i++) {
            if (parseInt(instr.ds_id) == st.units[i].disciplineId) {
              unitArr.push(st.units[i]);
            }
          }
          st.finalUnitArray = unitArr;

          st = {
            ...st,
            disciplineValue: {
              id: instr.ds_id,
              label: instr.ds_name,
            },
            instrumentNameId: instr.inst_instrumentName,
            calibrationProcedureNo: instr.inst_calibrationProcedureNo,
            referenceStandards: instr.inst_referenceStandards,
            formatNumber: instr.inst_formatNumber,
            revisionNumber: instr.inst_revisionNumber,
            revisionDate: instr.inst_revisionDate,
            updateEnableDisableO: gaugeArr.filter(
              (e) => instr.inst_isGauge == e.id
            )[0],
            materialType: instr.inst_materialtype,
            supportiveIds: st.supportive.filter(
              (s) =>
                sim?.[0]?.includes(s.id) &&
                sim?.[1]?.includes(s.standardRangesId)
            ),
            standardMasterIdArray: instr.inst_uncertaintyFactors,
            readingRows: rows,
            readingRangeValue: rowsSpace,
          };
        }
        setState({ ...st });
      })
      .catch((err) => console.log("data error : ", err));
  };

  const fetchInstrumentData = () => {
    let url = BASE_URL;
    return axios
      .get(
        url +
          `xjoin?_join=inst.instruments,_j,ds.discipline&_on1=(inst.disciplineId,eq,ds.id)&_fields=ds.name,ds.id,inst.materialtype,inst.disciplineId,inst.id,inst.standardMasterIds,inst.uncertaintyFactors,inst.supportiveInstrumentMasterData,inst.instrumentName,inst.calibrationProcedureNo,inst.referenceStandards,inst.isGauge,inst.formatNumber,inst.revisionNumber,inst.revisionDate&_where=(inst.id,eq,${params.id})`
      )
      .then((res) => {
        if (res.data.length) return res.data[0];
        return axios
          .get(
            BASE_URL +
              `instruments?_fields=materialtype,formatNumber,revisionNumber,revisionDate,disciplineId,id,standardMasterIds,uncertaintyFactors,supportiveInstrumentMasterData,instrumentName,calibrationProcedureNo,referenceStandards,isGauge&_where=(id,eq,${params.id})`
          )
          .then((res) => {
            return res.data.map((e) => ({
              inst_materialtype: e.materialtype,
              inst_disciplineId: e.disciplineId,
              inst_id: e.id,
              inst_standardMasterIds: e.standardMasterIds,
              inst_uncertaintyFactors: e.uncertaintyFactors,
              inst_supportiveInstrumentMasterData:
                e.supportiveInstrumentMasterData,
              inst_instrumentName: e.instrumentName,
              inst_calibrationProcedureNo: e.calibrationProcedureNo,
              inst_referenceStandards: e.referenceStandards,
              inst_isGauge: e.isGauge,
              inst_formatNumber: e.formatNumber,
              inst_revisionNumber: e.revisionNumber,
              inst_revisionDate: e.revisionDate,
            }))[0];
          });
      })
      .catch((err) => retErr("courses data error: ", err));
  };

  const getCmcReadings = () => {
    return axios
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${params.id})`)
      .then((res) => {
        return res.data;
      })
      .catch((err) =>
        retErr("Something Went Wrong while fetching standardRanges!", err)
      );
  };

  const fetchDeciplineData = () => {
    let url = BASE_URL;
    return axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => res.data)
      .catch((err) => retErr("disciplines data error: ", err));
  };

  const fetchStandardsData = () => {
    let url = BASE_URL;
    return axios
      .get(
        url +
          "standards?_fields=disciplineId,standardName,id,stId&_where=(status,eq,1)~and(type,eq,1)"
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => retErr("stabdards data error: ", err));
  };

  const fetchSupportiveData = () => {
    let url = BASE_URL;
    return axios
      .get(
        url +
          "xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.type,eq,2)"
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => retErr("supportive data error: ", err));
  };

  const fetchUncertinityData = () => {
    let url = BASE_URL;
    return axios
      .get(url + "uncertainty?_where=(status,eq,1)")
      .then((res) => {
        return res.data;
      })
      .catch((err) => retErr("uncertainty data error: ", err));
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    return axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        return res.data;
      })
      .catch((err) => retErr("unit data error: ", err));
  };

  const onSubmit = (e) => {
    let standardMasterIdArray = [];
    (state.standardMasterIdArray || "").split("|").forEach((element, index) => {
      standardMasterIdArray = [
        ...standardMasterIdArray,
        String(element.split(":")[0]),
      ];
    });
    standardMasterIdArray = standardMasterIdArray.join(",");

    let url = BASE_URL;
    var jsonData = {
      disciplineId: state.disciplineValue.id,
      instrumentName: state.instrumentNameId,
      standardMasterIds: standardMasterIdArray,
      calibrationProcedureNo: state.calibrationProcedureNo,
      referenceStandards: state.referenceStandards,
      formatNumber: state.formatNumber,
      revisionNumber: state.revisionNumber,
      // revisionDate: state.revisionDate,
      revisionDate: state.revisionDate
        ? moment(state.revisionDate).format("YYYY-MM-DD")
        : null,
      uncertaintyFactors: state.standardMasterIdArray,
      supportiveInstrumentMasterData: (state.supportiveIds || [])
        .map((e) => `${e.id}:${e.standardRangesId}`)
        .join(","),
      isGauge: state.updateEnableDisableO
        ? state.updateEnableDisableO.id
        : null,
      materialtype:
        state.materialType === "select material type" ? "" : state.materialType,
    };

    if (params.id)
      axios
        .patch(url + `instruments/${params.id}`, jsonData)
        .then((res) => {
          submitRanges(params.id);
        })
        .catch((error) => {
          toast.error(error);
        });
    else
      axios
        .post(url + "instruments", jsonData)
        .then((res) => {
          submitRanges(res.data.insertId);
        })
        .catch((error) => {
          toast.error(error);
        });
  };

  const submitRanges = (id) => {
    if (state.readingRows.length) {
      let rows = state.readingRows.map((row, i) => ({
        instrumentId: id,
        mode:
          state.readingRangeValue[i][1] && row[1]
            ? row[1] + "#" + state.readingRangeValue[i][1]
            : row[1],
        parameter:
          state.readingRangeValue[i][2] && row[2]
            ? row[2] + "#" + state.readingRangeValue[i][2]
            : row[2],
        paratype:
          state.readingRangeValue[i][3] && row[3]
            ? row[3] + "#" + state.readingRangeValue[i][3]
            : row[3],
        fromRange:
          state.readingRangeValue[i][4] && row[4]
            ? row[4] + "#" + state.readingRangeValue[i][4]
            : row[4],
        toRange:
          state.readingRangeValue[i][5] && row[5]
            ? row[5] + "#" + state.readingRangeValue[i][5]
            : row[5],
        lowerCmc:
          state.readingRangeValue[i][6] && row[6]
            ? row[6] + "#" + state.readingRangeValue[i][6]
            : row[6],
        higherCmc:
          state.readingRangeValue[i][7] && row[7]
            ? row[7] + "#" + state.readingRangeValue[i][7]
            : row[7],
      }));
      let post = [],
        postIndexes = [],
        patch = [];
      rows.map((r, i) => {
        if (state.readingRows[i][0] === "") {
          post.push(r);
          postIndexes.push(i);
        } else {
          patch.push([state.readingRows[i][0], r]);
          postIndexes.push(undefined);
        }
      });
      console.log("rows", rows);
      Promise.all(
        (post.length > 0
          ? [axios.post(BASE_URL + "cmc/bulk", post)]
          : []
        ).concat(
          patch.length > 0
            ? [...patch.map((p) => axios.patch(BASE_URL + `cmc/${p[0]}`, p[1]))]
            : []
        )
      )
        .then((res) => {
          console.log("cmc added");
          if (params.id === undefined) {
            toast.success("Instrument Created Successfully!");
            setTimeout(() => {
              refresh();
            }, 500);
          } else if (post.length > 0) {
            toast.success("Instrument Updated Successfully!");
            let newRows = [...state.readingRows];
            let idx = 0;
            postIndexes.map((i) => {
              if (i !== undefined) {
                newRows[i].id = res[idx];
                idx += 1;
              }
            });
            setState({
              readingRows: newRows,
            });
          } else toast.success("Instrument Updated Successfully!");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else if (params.id !== undefined) refresh();
  };

  useEffect(() => {
    getData();
  }, []);

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...state.readingRangeValue];
    newRows[rowIndex][colIndex] = value;
    setState({
      readingRangeValue: [...newRows],
    });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...state.readingRows];
    newRows[rowIndex][colIndex] = value;
    setState({
      readingRows: [...newRows],
    });
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...state.readingRows];
    newReadingRows.push(["", "", "", "", "", "", "", ""]);
    let newReadingRowss = [...state.readingRangeValue];
    newReadingRowss.push(["", "", "", "", "", "", "", ""]);
    let st = {
      readingRows: [...newReadingRows],
      readingRangeValue: [...newReadingRowss],
    };
    setState({ ...st });
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...state.readingRows];
    axios
      .delete(BASE_URL + `cmc/${newReadingRows[rowIndex][0]}`)
      .then((res) => {
        console.log("cmc deleted succesfully!");
        toast.success("cmc deleted succesfully!");
        newReadingRows.splice(rowIndex, 1);
        setState({
          readingRows: [...newReadingRows],
        });
      })
      .catch((err) => {
        console.log("cmc deleted error : ", err);
        toast.error("cmc deleted err : ", err);
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create new instrument
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            disabled={!isNaN(Number(params.id))}
            size="small"
            id="combo-box-demo"
            options={[...state.disciplineArr]}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            defaultValue={undefined}
            value={state.disciplineValue || {}}
            getOptionLabel={(option) => option.label || ""}
            onChange={(event, value) => {
              let st = {};
              if (value != null) {
                let disciplineKeys = false;
                state.disciplines.forEach((el) => {
                  if (el.id == value.id) {
                    if (el.disciplineKey == "0") {
                      disciplineKeys = true;
                    }
                  }
                });
                if (disciplineKeys) {
                  st.et = false;
                } else {
                  st.et = true;
                }
                const unitArr = [""];
                for (let i = 0; i < state.units.length; i++) {
                  if (parseInt(value.id) == state.units[i].disciplineId) {
                    unitArr.push(state.units[i]);
                  }
                }
                st.finalUnitArray = unitArr;
                st.disciplineId = value.id;
                st.filteredStandards = filterStandards(
                  state.standards,
                  value.id
                );
              } else {
                st.disciplineId = null;
              }
              st.disciplineValue = value;
              setState({ ...st });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Instrument name *"
            size="small"
            fullWidth
            variant="outlined"
            value={state.instrumentNameId || ""}
            onChange={(e) => {
              setState({
                instrumentNameId: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Calibration Procedure No."
            size="small"
            fullWidth
            variant="outlined"
            value={state.calibrationProcedureNo || ""}
            onChange={(e) => {
              setState({
                calibrationProcedureNo: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Is Standards *"
            size="small"
            fullWidth
            variant="outlined"
            value={state.referenceStandards || ""}
            onChange={(e) => {
              setState({
                referenceStandards: e.target.value,
              });
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            size="small"
            options={state.supportive || []}
            defaultValue={undefined}
            value={state.supportiveIds || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supportive Instruments *"
                placeholder="select multiple supportive instruments"
              />
            )}
            onChange={(event, value) => {
              setState({
                supportiveIds: value,
              });
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            onChange={(_, val) => {
              setState({
                updateEnableDisableO: val,
              });
            }}
            value={state.updateEnableDisableO}
            options={gaugeArr}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Gauge" />}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={materialtypeOp.map((e) => ({
              label: e,
            }))}
            defaultValue={state.materialType}
            renderInput={(params) => (
              <TextField {...params} label="Material Type *" />
            )}
            onInputChange={(event, newInputValue) => {
              setState({
                materialType: newInputValue,
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Format Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={state.formatNumber || ""}
            onChange={(e) => {
              setState({
                formatNumber: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Revision Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={state.revisionNumber || ""}
            onChange={(e) => {
              setState({
                revisionNumber: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Revision Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={state.revisionDate ? new Date(state.revisionDate) : ""}
              onChange={(newValue) => {
                setState({
                  revisionDate: newValue,
                });
              }}
              // onChange={(newValue) => {
              //   updateQuoObject("revisionDate", newValue);
              // }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* {state.standardMasterIdArray && */}
          <DisplayTable
            value={state.standardMasterIdArray}
            standards={state.filteredStandards}
            uncertaintyMasterData={state.uncertaintyMasterData}
            state={state}
            setState={setState}
            // rows={rows}
          />
          {/* } */}
        </Grid>
      </Grid>

      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>CMC</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {state.readingColumns[0]
                  .filter((e) => e.toggle === undefined || !state.et)
                  .map((column) => (
                    <TableCell key={column.field}>
                      {" "}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 0 &&
                      cellIndex != 1 &&
                      cellIndex != 2 &&
                      cellIndex != 3
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <br />
                          <select
                            // disabled={plus}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {state.finalUnitArray?.map((unit) => {
                              if (
                                unit.symbol ==
                                state.readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1 && !state.et) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "150px" }}
                            size="small"
                            id="combo-box-demo"
                            value={cell}
                            options={mode.map((e) => ({
                              label: e,
                            }))}
                            renderInput={(params) => (
                              <TextField {...params} label="Mode *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            // disabled={plus}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {state.finalUnitArray?.map((unit) => {
                              if (
                                unit.symbol ==
                                state.readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 2 && !state.et) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "250px" }}
                            size="small"
                            id="combo-box-demo"
                            value={cell}
                            options={
                              parameters[row[1]]
                                ? parameters[row[1]].map((e) => ({
                                    label: e,
                                  }))
                                : []
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Parameter *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            // disabled={(plus || !row[2]) ? true : false}
                            disabled={!row[1] ? true : false}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {state.finalUnitArray?.map((unit) => {
                              if (
                                unit.symbol ==
                                state.readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3 && !state.et) {
                      return (
                        <TableCell>
                          {!(paratype[row[1]] && paratype[row[1]][row[2]]) ? (
                            <TextField
                              size="small"
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                              disabled={!row[2]}
                            ></TextField>
                          ) : (
                            <Autocomplete
                              style={{ width: "150px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={
                                paratype[row[1]] && paratype[row[1]][row[2]]
                                  ? paratype[row[1]][row[2]].map((e) => ({
                                      label: e,
                                    }))
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Parameter Type *"
                                />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                              // disabled={
                              //   plus ||
                              //   !(paratype[row[3]] && paratype[row[3]][row[4]])
                              //     ? true
                              //     : false
                              // }
                            />
                          )}
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {state.finalUnitArray?.map((unit) => {
                              if (
                                unit.symbol ==
                                state.readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={(e) => {
            onSubmit(e);
          }}
        >
          {params.id ? "Update Instrument" : "Create Instrument"}
        </Button>
      </Toolbar>
      {/* <ToastContainer /> */}
    </Paper>
  );
};

export default CreateInstrument;
