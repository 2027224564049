import { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import { useParams } from "react-router";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const axios = require("axios");

const keys = [
  { id: 0, label: "Electro-technical" },
  { id: 1, label: "Others" },
  { id: 2, label: "mass & volume" },
];

export default function CreateDiscipline(props) {
  const [disciplineName, setDisciplineName] = useState("");
  const [environmentalConditions, setEnvironmentalConditions] = useState("");
  const [remarks, setRemark] = useState("");
  const [key, setKey] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [issueNo, setIssueNo] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [amendmentNo, setAmendmentNo] = useState("");
  const [amendmentDate, setAmendmentDate] = useState("");
  const params = useParams();

  const onSubmit = () => {
    var jsonData = {
      name: disciplineName,
      disciplineKey: key.id,
      environmentalConditions: environmentalConditions,
      remarks,
      documentNumber,
      issueNo,
      issueDate: moment(issueDate).format("YYYY-MM-DD"),
      amendmentNo,
      amendmentDate: moment(amendmentDate).format("YYYY-MM-DD"),
    };
    let url = BASE_URL;
    (() =>
      params.id
        ? axios.patch(url + `discipline/${params.id}`, jsonData)
        : axios.post(url + "discipline", jsonData))()
      .then((res) => {
        props.setLoader(false);
        toast("new discipline added!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  const getStandardIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + `discipline/${params.id}`)
      .then((res) => {
        setDisciplineName(res.data[0].name);
        setKey(keys.filter((k) => k.id == res.data[0].disciplineKey)[0] || "");
        setRemark(res.data[0].remarks);
        setDocumentNumber(res.data[0].documentNumber);
        setIssueNo(res.data[0].issueNo);
        setIssueDate(res.data[0].issueDate);
        setAmendmentNo(res.data[0].amendmentNo);
        setAmendmentDate(res.data[0].amendmentDate);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) {
      getStandardIdList();
    }
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        {params.id ? "Update Discipline" : "Create New Discipline"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Discipline name *"
            size="small"
            fullWidth
            value={disciplineName}
            variant="outlined"
            onChange={(e) => setDisciplineName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={keys}
            value={key}
            renderInput={(params) => <TextField {...params} label="Key name" />}
            onChange={(_, val) => {
              setKey(val);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Document Number *"
            size="small"
            fullWidth
            value={documentNumber}
            variant="outlined"
            onChange={(e) => setDocumentNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Issue Number *"
            size="small"
            fullWidth
            type="integer"
            value={issueNo}
            variant="outlined"
            onChange={(e) => setIssueNo(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Issue Date *"
              value={issueDate ? new Date(issueDate) : ""}
              onChange={(e) => setIssueDate(e)}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Amendment Number *"
            size="small"
            fullWidth
            value={amendmentNo}
            variant="outlined"
            onChange={(e) => setAmendmentNo(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Amendment Date *"
              value={amendmentDate ? new Date(amendmentDate) : ""}
              onChange={(e) => setAmendmentDate(e)}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Remarks *"
            size="small"
            multiline
            minRows={5}
            maxRows={5}
            fullWidth
            value={remarks}
            variant="outlined"
            onChange={(e) => setRemark(e.target.value)}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          {params.id ? "Update Disciplne" : "Create Discipline"}
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
