import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import moment from "moment";
import { Grid, Paper, TextField, Autocomplete, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useReactToPrint } from "react-to-print";

import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";

const axios = require("axios");

export default function ViewSrf() {
  const navigate = useNavigate();
  const printComponentRef = React.useRef();
  const params = useParams();
  const [srfObject, createSrfObject] = React.useState([]);
  const [clientArray, setClientArray] = React.useState([]);
  const [srfInstruments, setSrfInstruments] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  const [instrument, setInstrument] = React.useState(null);
  var [allInstruments, setInstruments] = React.useState([]);
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);

  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [commitedDate, setCommitedDate] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    axios
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err);
      });
  }

  const getSrfsList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=pl.srfs,_j,pr.clients&_on1=(pl.clientId,eq,pr.id)&_fields=pl.clientId,pr.companyName,pl.serviceReqNumber,pl.committedDeliveryDate,pl.dcDate,pl.entryDate,pl.dcNumber,pl.committedDeliveryDate,pl.receiptDate,pl.id,pl.address&_where=(pl.status,eq,1)"
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setServiceReqNumber(res.data[0].pl_serviceReqNumber);
          setReceiptDate(res.data[0].pl_receiptDate);
          setDcDate(res.data[0].pl_dcDate);
          setEntryDate(res.data[0].pl_entryDate);
          setCommitedDate(res.data[0].pl_committedDeliveryDate);
          setDcNumber(res.data[0].pl_dcNumber);
        }
        fetchInstrument(res.data[0].cer_instrumentId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        // toast.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        setSrfInstruments(res.data);
      })
      .catch((error) => {
        // toast.error("Something Went Wrong!");
      });
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `instruments?_where=(status,eq,1`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      rows.push([srfInstruments[i].id, srfInstruments[i].instrumentId]);
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    console.log(rows);
    setReadingRows(rows);
  };

  var pushCArray = [];
  for (let i = 0; i < clientArray.length; i++) {
    pushCArray.push({
      label: clientArray[i].id + ", " + clientArray[i].companyName,
    });
  }

  var pushArray = [];

  for (let i = 0; i < srfInstruments.length; i++) {
    pushArray.push({
      instrumentId: allInstruments.find(
        (element) => element.id == srfInstruments[i].instrumentId
      )?.instrumentName,
      ranges: srfInstruments[i].ranges,
      lc: srfInstruments[i].lc,
      make: srfInstruments[i].make,
      conOfReceipt: srfInstruments[i].conOfReceipt,
      calFrequency: srfInstruments[i].calFrequency,
      accuracy: srfInstruments[i].accuracy,
      model: srfInstruments[i].model,
      serialNumber: srfInstruments[i].serialNumber,
      DUCID: srfInstruments[i].DUCID,
      calPoint: srfInstruments[i].calPoint,
      ConOfDuc: srfInstruments[i].ConOfDuc,
      calFrequency: srfInstruments[i].calFrequency,
    });
  }
  console.log("pushArray", pushArray);

  var dataaa = [1, "2", 3, "4", 5, 6, 7];

  const [readingRows, setReadingRows] = React.useState([dataaa]);

  useEffect(() => {
    srfInstrumentsList();
    getClientIdList();
    getSrfsList();
    instrumentsList();
  }, []);

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  var refresh = () => {
    window.location.reload(false);
  };

  var refresh = () => {
    window.location.reload(false);
    navigate("/srf");
  };
  
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicHeader = async (pageNo) => {
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;height: 30px;">
      <tr>
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Document Number</b>
            <br>
            <span style="font-size:19px;">Document Number</span>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Issue No. | Date</b>
            <br>
            <span style="font-size:19px;">Issue No. | Date</span>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Revision No. | Date</b>
            <br>
            <span style="font-size:19px;">Revision No. | Date</span>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;line-height:25px;">
            <b style="font-size:20px;">Page Number</b>
            <br>
            <span style="font-size:19px;">${pageNo}</span>
          </td>
          </tr>
        </table>
      </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate
  ) => {
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CALIBRATED BY <br> Electronically signed by </b>
                <br>
                <span style="font-size:19px;">${calibratedby}<br>${moment(
                    calibrationDate
                  ).format("DD-MM-YYYY")}</span>
            </td>`
                : `<td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: left;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;vertical-align: top;">CALIBRATED BY</b>
            </td>`
            }
            </td>
            ${
              electronicSign
                ? `<td style="padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:20px;">CHCECKED AND APPROVED BY <br> Electronically signed by </b> <br>
                <span style="font-size:19px;">${approvedby}<br>${moment(
                    calibrationDate
                  ).format("DD-MM-YYYY")}</span>`
                : `
            <td
                style="vertical-align: top;height:100px;padding:5px;width:50%;text-align: right;font-family: Calibri, sans-serif;line-height:30px;">
                <b style="font-size:17px;vertical-align: top;">CHCECKED AND APPROVED BY </b>`
            }
            </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [140, 1, 165, 1],
        filename: `certificate_${params.id}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");

      let image = await generateDynamicHeader(i);
      pdf.addImage(image, 0, 70, 595, 80);

      image = await generateDynamicFooter();
      pdf.addImage(image, 0, 675, 595, 100);

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 772, 595, 70);
      }
    }

    pdf.save();
  };
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "With Electronically Signed" },
              { id: 2, label: "Without  Electronically Signed" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Electronically Signed" />
            )}
            onInputChange={(event, newInputValue) => {
              console.log("newValue : ", newInputValue);
              if (
                newInputValue === "With Electronically Signed" ||
                newInputValue === ""
              ) {
                setElectronicSign(true);
              } else if (newInputValue === "Without  Electronically Signed") {
                setElectronicSign(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <br />
      <div className="srf" ref={printComponentRef}>
        <div style={{ width: "100%" }} id="header-section"></div>
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            border: "1px solid black",
            lineHeight: "16px",
          }}
        >
          <div className="row" style={{ lineHeight: "16px" }}>
            <div className="col-3"></div>
            <div className="col-6 mt-2">
              <h4 className="font-bold text-center">SERVICE REQUEST FORM</h4>
            </div>
          </div>
        </div>
        <div
          className="srfFulfilled"
          style={{
            width: "95%",
            margin: "0 auto",
            border: "1px solid black",
            borderTop: "none",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          <h5>To be filled by customer </h5>
          <div style={{ fontSize: "14px" }}>
            <table
              style={{ width: "95%", margin: "auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  SRF Number
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {serviceReqNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Date of Receipt
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {moment(receiptDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Number
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {dcNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {moment(dcDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Entry Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  {moment(entryDate).format("DD-MM-YYYY")}
                </td>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Calibration to be completed on
                </th>
                <td style={{ padding: "7px", width: "250px" }}>
                  {moment(commitedDate).format("DD-MM-YYYY")}
                </td>
              </tr>
            </table>
            <br />
            <table
              style={{ width: "95%", margin: "auto" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    colSpan={11}
                    style={{ padding: "5px", borderBottom: "none" }}
                  >
                    INSTRUMENT DETAILS
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    SI.No
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Description of the Equipment
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Range
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Make
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Model
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Serial Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Identification Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Calibration Points If any
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Requested Due Date
                  </th>
                  <th style={{ padding: "7px" }}>Condition of UUC / Remarks</th>
                </tr>
              </thead>
              {pushArray.length > 0 ? (
                <tbody>
                  {pushArray.map((item, index) => {
                    console.log(item, "item");
                    return (
                      <tr style={{ lineHeight: "13px" }}>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.instrumentId}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.ranges
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.make
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.model}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.serialNumber}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.DUCID}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.calPoint}{" "}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          1
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.calFrequency}
                        </td>
                        <td style={{ padding: "7px", borderTop: "none" }}>
                          {item.ConOfDuc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="11">No items added</td>
                  </tr>
                </tbody>
              )}
            </table>
            <br />
            <table
              style={{ width: "95%", margin: "auto" }}
              className="srftable"
            >
              <thead>
                <tr style={{ textAlign: "left" }}>
                  <th
                    style={{
                      padding: "7px",
                      width: "20%",
                      borderBottom: "none",
                      borderRight: "none",
                    }}
                  >
                    Remarks if any
                  </th>
                  <td
                    style={{
                      padding: "7px",
                      width: "80%",
                      borderBottom: "none",
                    }}
                  ></td>
                </tr>
                <tr style={{ textAlign: "left" }}>
                  <th
                    style={{
                      padding: "7px",
                      borderBottom: "none",
                      borderRight: "none",
                    }}
                  >
                    Date of completion
                  </th>
                  <td style={{ padding: "7px", borderBottom: "none" }}></td>
                </tr>
                <tr style={{ textAlign: "left" }}>
                  <th
                    style={{
                      padding: "7px",
                      borderBottom: "none",
                      borderRight: "none",
                    }}
                  >
                    Calibrated by
                  </th>
                  <td style={{ padding: "7px", borderBottom: "none" }}></td>
                </tr>
                <tr style={{ textAlign: "left" }}>
                  <th style={{ padding: "7px", borderRight: "none" }}>
                    Authorized by
                  </th>
                  <td style={{ padding: "7px" }}></td>
                </tr>
              </thead>
            </table>
            <br />
          </div>
        </div>
      </div>

      <ToastContainer />
    </Paper>
  );
}
