import React, { useEffect } from "react";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";

import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import Card from "react-bootstrap/Card";
import Modal from "@mui/material/Modal";
import QRCode from "react-qr-code";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Autocomplete, Avatar, Grid, TextField, Toolbar } from "@mui/material";

const axios = require("axios");

export default function ViewSummary() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dsList, setDSList] = React.useState([]);

  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const printComponentRef = React.useRef();
  const params = useParams();

  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 40 },
    { id: "ulrNo", label: "ULR No.", minWidth: 40 },
    { id: "certificateNumber", label: "Certificate No.", minWidth: 40 },
    {
      id: "InstrumentId",
      label: "Instrument Name",
      minWidth: 40,
      align: "left",
    },
    {
      id: "ranges",
      label: "Range",
      minWidth: 40,
      align: "left"
    },
    {
      id: "lc",
      label: "LC",
      minWidth: 40,
      align: "left",
    },
    {
      id: "accuracy",
      label: "Accuracy",
      minWidth: 40,
      align: "left",
    },
    {
      id: "serialNumber",
      label: "Serial Number",
      minWidth: 40,
      align: "left",
    },
    {
      id: "ducId",
      label: "Duc Id",
      minWidth: 40,
      align: "left",
    },

    {
      id: "calibrationDate",
      label: "Calibration date",
      minWidth: 40,
      align: "left",
    },
    {
      id: "dueDate",
      label: "Due date",
      minWidth: 40,
      align: "left",
    },
  ];
  const getDSList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
        "xjoin?_join=ds.datasheets,_j,client.clients,_j,inst.instruments,_j,cert.certificates&_on1=(ds.clientId,eq,client.id)&_on2=(ds.instrumentId,eq,inst.id)&_on3=(cert.id,eq,ds.id)&_fields=client.companyName,client.contact,client.address,inst.instrumentName,cert.ULRNo,cert.certificateNumber,ds.calibrationDate,ds.nextDueDate,client.id,ds.id,ds.lc,ds.DUCID,ds.ranges,ds.accuracy,ds.serialNumber"
      )
      .then((res) => {
        var finalArr = [];
        const splitIdArr = params.id.split(",");
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < splitIdArr.length; j++) {
            if (parseInt(splitIdArr[j]) == res.data[i].ds_id) {
              res.data[i].ds_ranges = res.data[i].ds_ranges.split("||").map(range => {
                let tmp = range.split("|")
                tmp[0] = tmp[0].split("#")
                if (tmp[1]) {
                  tmp[1] = tmp[1].split("#")
                }
                return tmp[1] ? `${tmp[0][0]} to ${tmp[1][0]}${tmp[0][1] ? ` ${tmp[0][1]}` : ""}` : `${tmp[0][0]}${tmp[0][1] ? ` ${tmp[0][1]}` : ""}` 
              })
              res.data[i].ds_lc = res.data[i].ds_lc.replaceAll("|", ", ").replaceAll("#", " ")
              res.data[i].ds_accuracy = res.data[i].ds_accuracy.replaceAll("|", ", ").replaceAll("#", " ")
              finalArr.push(res.data[i]);
            }
          }
        }
        setDSList(finalArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };


  const generatePDF = async () => {
    const element = printComponentRef.current;
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [80, 1, 135, 1],
        filename: `SummaryReport.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("Courier");


      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 790, 595, 50);
      }

    }

    pdf.save();
  };

  useEffect(() => {
    getDSList();
  }, []);

  // const submitSiteDelete = (id) => {
  //   console.log(id)

  //   var jsonData = {
  //     status:-1
  //   };
  //   let url = BASE_URL;
  //   axios
  //     .patch(url + `discipline/${id}`,jsonData)
  //     .then((res) => {
  //       toast("Discipline was deleted!");
  //       setTimeout(refresh, 1000);
  //     })
  //     .catch((error) => {
  //       toast.error("Something Went Wrong!");
  //     });
  // };

  const qrModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const renderQrModal = () => {
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={qrModalStyle}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            QR Code of Certificate
          </Typography>
          <hr />
          <div align="center">
            <QRCode value={qrCodeUrl} align="center" />
            <hr />
            <Button size="small" align="center">
              Print
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar style={{ padding: "0px", width: "100%" }}>

          <Grid container spacing={2} align="left" style={{ display: "flex" }}>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Letter Head" },
                  { id: 2, label: "Without Letter Head" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="letterHead" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (
                    newInputValue === "With Letter Head" ||
                    newInputValue === ""
                  ) {
                    setLetterHead(true);
                  } else if (newInputValue === "Without Letter Head") {
                    setLetterHead(false);
                  }
                  // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="combo-box-demo"
                options={[
                  { id: 1, label: "With Electronically Signed" },
                  { id: 2, label: "Without  Electronically Signed" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Electronically Signed" />
                )}
                onInputChange={(event, newInputValue) => {
                  console.log("newValue : ", newInputValue);
                  if (
                    newInputValue === "With Electronically Signed" ||
                    newInputValue === ""
                  ) {
                    setElectronicSign(true);
                  } else if (
                    newInputValue === "Without  Electronically Signed"
                  ) {
                    setElectronicSign(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                size="small"
                sx={{ ml: 3 }}
                onClick={() => {
                  generatePDF();
                }}
              >
                Download PDF
              </Button>
            </Grid>
          </Grid>

      </Toolbar>
      <h4 style={{ color: "#000066", margin: "10px 0px" , alignItems:"center" }}>
                    <b>  Print Preview</b>
                  </h4>
                  
          <hr />
          <div
             ref={printComponentRef}
             style={{ width: "100%", margin:"auto" }}

          >

                  <Grid container spacing={2}>
                <Grid md={12} className="font-link" align="center">
                  <h5 style={{ color: "#000066", margin: "10px 0px" }}>
                  <b>  Company Name: {dsList[0]?.client_companyName}</b>
                  </h5>
                </Grid>
              </Grid>
          <Table
            stickyHeader
            aria-label="sticky table"
            class="table-responsive"
            size="small"
            id="datatable-keytable"
            width="100%"
            style={{ width: "100%" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {dsList &&
                dsList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.cert_ULRNo}</TableCell>
                        <TableCell>{row.cert_certificateNumber}</TableCell>
                        <TableCell>{row.inst_instrumentName}</TableCell>
                        <TableCell>{row.ds_ranges}</TableCell>
                        <TableCell>{row.ds_lc}</TableCell>
                        <TableCell>{row.ds_accuracy}</TableCell>
                        <TableCell>{row.ds_serialNumber}</TableCell>
                        <TableCell>{row.ds_DUCID}</TableCell>
                        <TableCell>
                          {row.ds_calibrationDate
                            ? moment(row.ds_calibrationDate).format(
                              "DD-MM-YYYY"
                            )
                            : ""}
                        </TableCell>
                        <TableCell>
                          {row.ds_nextDueDate
                            ? moment(row.ds_nextDueDate).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <div style={{ width: "100%" }} id="header-section"></div>
          </div>
          <br />
          
      </TableContainer>

      {renderQrModal()}
    </>
  );
}
